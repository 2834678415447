// Font Awesome
$fa-font-path: "../webfonts";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Admin LTE
@import 'admin-lte/dist/css/adminlte.css';

// Pick a Day
@import 'pikaday/css/pikaday.css';
@import 'pikaday/scss/pikaday';

// Fontawesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
